import axios from 'axios';

/**
 * axios 인스턴스 생성
 * @param {string} baseUrl : 서비스 기본 Url
 * @param {string} authenticate : 인증된 서비스 호출 여부 (default: true)
 * @returns 
 */
const createAxiosInstance = (baseUrl, authenticate) => {

    const options = {
        baseURL: baseUrl,
        authenticate : authenticate,
        withCredentials: true
    };

    options.headers = {       
        'Content-Type': `application/json; charset=utf-8`,
        'Accept': `appliction/json`,
    };

    // 인증이 필요한 서비스 호출 시 FoxRest-Authenticate 헤더값 설정
    if (authenticate) options.headers['FoxRest-Authenticate'] = window.sessionStorage.getItem('FoxRest-Authenticate');

    const instance = axios.create(options);

    // Add a request interceptor
    instance.interceptors.request.use(
        requestConfig => requestConfig,
        (requestError) => {

            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    instance.interceptors.response.use(
        response => response,
        (error) => {
            console.log('client.js(1)', error)
            if (error.response.status != 200) {
                
                const contentType = error.response.headers['content-type'];
                let errorInfo = null;
                
                if (contentType != null && contentType.indexOf("application/json") >= 0) {

                    errorInfo = error.response.data;
                    console.log('client.js(2)', errorInfo);
                }
                else errorInfo = new FoxServiceErrorInfo();
                
                return Promise.reject(errorInfo);
            }
        },
    );

    return instance;
};

/**
 * FoxRestClient 클래스
 */
class FoxRestClient {

    /**
     * 생성자
     * @param {string} url 
     * @param {boolean} authenticate 
     */
    constructor(url, authenticate = true) {

        this.axiosInstance = createAxiosInstance(url, authenticate);
        this.baseUrl = url;
    }

    /**
     * 실제 서비스 호출 (POST 방식만 지원함)
     * @param {*} operaion 
     * @param {*} request 
     * @returns 
     */
    invoke(operaion, request) {
       
        return new Promise((resolve, reject) => {

            const data = JSON.stringify(request);

            this.axiosInstance.post(`/${operaion}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error)); 
        })
    }

    /**
     * executeDataSet
     * @param {*} request 
     * @returns 
     */
    executeDataSet(request) {

        return new Promise((resolve, reject) => {

            this.invoke("executedataset", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }

    /**
     * executeNonQuery
     * @param {*} request 
     * @returns 
     */
    executeNonQuery(request) {

        return new Promise((resolve, reject) => {

            this.invoke("executenonquery", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }

    /**
     * executeScalar
     * @param {*} request 
     * @returns 
     */
    executeScalar(request) {
        return new Promise((resolve, reject) => {

            this.invoke("executescalar", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }

    /**
     * executeSaveDataTable
     * @param {*} request 
     * @returns 
     */
    executeSaveDataTable(request) {

        return new Promise((resolve, reject) => {

            this.invoke("saveDataTable", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }

    /**
     * executeMultiple
     * @param {*} request 
     * @returns 
     */
    executeMultiple(request) {

        return new Promise((resolve, reject) => {

            this.invoke("executeMultiple", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }
}

/**
 * FoxRestBizClient 클래스
 * 비즈니스 레이어 영역의 메소드 호출 시 사용
 */
class FoxRestBizClient {

    /**
     * 생성자
     * @param {string} url 
     * @param {생성자} authenticate 
     */
    constructor(url, authenticate = true) {

        this.client = createAxiosInstance(url, authenticate);
        this.baseUrl = url;
    }

    /**
     * 실제 서비스 호출 (POST만 지원)
     * @param {*} operaion 
     * @param {*} request 
     * @returns 
     */
    invoke(operaion, request) {

        return new Promise((resolve, reject) => {

            const data = JSON.stringify(request);
            
            this.client.post(`/${operaion}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error));
        })
    }

    /**
     * execute
     * @param {*} request 
     * @returns 
     */
    execute(request) {

        return new Promise((resolve, reject) => {

            this.invoke("execute", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }

    /**
     * executeMultiple
     * @param {*} request 
     * @returns 
     */
    executeMultiple(request) {

        return new Promise((resolve, reject) => {

            this.invoke("executeMultiple", request)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    }
}

/**
 * 파라메타 전달 클래스
 */
class FoxBizRequest {

    /**
     * 생성자
     */
    constructor() {

        this.parameters = new FoxServiceParameterCollection();
    }
}

/**
 * FoxDataRequest 클래스
 */
class FoxDataRequest {

    /**
     * 생성자
     */
    constructor() {

        this.parameters = new FoxServiceParameterCollection();
    }
}

/**
 * REST API 오류 정보 클래스
 */
class FoxServiceErrorInfo {

    constructor() {

        this.Message = null,
        this.MessageDetail = null
    }
}

/**
 * FoxServiceParameterCollection 클래스
 */
class FoxServiceParameterCollection {
    
    /**
     * 생성자
     */
    constructor() { }
}

export { FoxRestClient, FoxRestBizClient, FoxBizRequest, FoxDataRequest };